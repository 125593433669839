import React, { useState, useEffect, useRef } from "react";
import Header from "./../../../components/common/public/header";
import Footer from "../../../components/common/public/footer";
import {
  getPublicDomains,
  getPublicDomainSlugStatus,
} from "./../../../apis/index.js";
import { useHistory } from "react-router-dom";
import "antd/dist/antd.css";
import { Select } from "antd";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
function PrivacyPolicy() {
  const [slug, setSlug] = useState("");
  const [step, setStep] = useState(0);
  const [domain, setDomain] = useState([]);
  const [domains, setDomains] = useState([]);

  const [responseClass, setResponseClass] = useState("");

  const [response, setResponse] = useState([]);
  const user = useSelector((state) => (state.auth ? state.auth.user : {}));

  const { isLoggedin } = user;
  // console.log("isLoggedin", isLoggedin);
  let history = useHistory();


  useEffect(() => {
    executeScroll()
    getAllPublicDomains();
  }, []);

  const myRef = useRef(null)

  const executeScroll = () => myRef.current.scrollIntoView()


  const getAllPublicDomains = async () => {
    try {
      let response = await getPublicDomains();
      if (response.status === 200) {
        setDomains(response.data.domains);
      }
    } catch (error) {
      console.log(error);
    }
  };

  console.log("domains", domains);
  const { Option } = Select;

  const checkStatusSlugDomain = async () => {
    try {
      let response = await getPublicDomainSlugStatus(domain, slug);
      if (response.status === 200) {
        setResponseClass("greenText");
        setResponse("This linkname is available, reserve it now!");
        toast.success(response.data.message, {
          position: "top-center",
          theme: "dark",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        setResponseClass("redText");
        setResponse("Sorry, this linkname is taken, please try again!");
        toast.error(response.data.message, {
          position: "top-center",
          theme: "dark",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      //   alert(error.);
      console.log(error);
    }
  };
  function onChange(value) {
    console.log(`selected ${value}`);
    setDomain(value);
  }

  function onBlur() {
    console.log("blur");
  }

  function onFocus() {
    console.log("focus");
  }

  function onSearch(val) {
    console.log("search:", val);
  }

  const selectDomain = (val) => {
    console.log(val);
    setDomain(val);
    setStep(2);
  };

  return (
    <div>
      {isLoggedin === false || (isLoggedin === undefined && <Header />)}
      <section ref={myRef} className="detailsSection">
        <div className="container">
          <div className="col-lg-10 mx-auto">
            <>
              <h2 className="detailsHeading">Privacy Policy</h2>
              <h3 className="detailsSubHeading">Last Modified: January 8, 2025</h3>
              <h3 className="detailsSubHeading">Introduction</h3>
              <span style={{ fontWeight: 400 }}>Ziphost™</span> values the privacy and
              security of the information you share with us through
              https://www.ziphost.app/. We also want you to feel comfortable using our
              website. You must understand how we collect, use, and maintain your
              information. This Privacy Policy deals with personally identifiable
              information ("Data" below) we may collect on our site. This Policy does not
              apply to other entities that we do not own or control or persons that are not
              our employees, agents, or within our control. Please take time to read our
              Terms of Use. By using the <span style={{ fontWeight: 400 }}>Ziphost™</span>{" "}
              website or any <span style={{ fontWeight: 400 }}>Ziphost™</span>{" "}
              products/services, you agree to the terms of this Privacy Policy and, as
              applicable, the{" "}
              <a href="https://www.ziphost.app/terms-of-use">Terms of Use</a>.
              <h3 className="detailsSubHeading">What types of data do we collect</h3>
              <b>Personal Information:</b> It includes your name, address information, email
              address and URL, telephone number, account username and password, credit/debit
              card numbers and associated billing addresses (if intended to make a purchase)
              and expiration dates, account numbers, as well as other, similar information.
              If you contact our technical support, we may process other data required to
              help you with any queries or support matters, such as login and password to
              the <span style={{ fontWeight: 400 }}>Ziphost™</span> dashboard administrator,
              FTP access, data collected in crash logs that are gathered by your CMS or the
              technical parameters of the server you use. Such information is only collected
              if you voluntarily submit it to us. If you do not agree to provide us with the
              above data, you cannot use/download our products and/or get effective support
              from <span style={{ fontWeight: 400 }}>Ziphost™</span>.
              <b>Non-Personal Information:</b> We gather statistical and other analytical
              information collected on an aggregate basis from all visitors to our website.
              This Non-Personal Data comprises information that cannot be used to identify
              or contact you, such as demographic information regarding, for example, user
              IP addresses where they have been clipped or anonymized, browser types, and
              other anonymous statistical data involving the use of our website.
              <h3 className="detailsSubHeading">What purposes your data is used for</h3>
              With the data stored on our site, we identify you as our
              customer/subscriber/patron and deliver products/notices and other marketing
              materials. As a visitor, you aren’t allowed to purchase any product, subscribe
              to a newsletter, or make a comment anonymously. So, these data are the only
              means to identify you and allow you to take these actions on our site.
              <h3 className="detailsSubHeading">
                How long <strong>Ziphost™</strong> retains your information
              </h3>
              When you register for a <span style={{ fontWeight: 400 }}>Ziphost™</span>{" "}
              account, we process and keep the personal data we have about you for as long
              as you don’t delete the account/unsubscribe yourself (subject to laws and
              regulations).
              <h3 className="detailsSubHeading">What we don’t do with your data</h3>
              We do not and will never share, disclose, sell, rent, or provide your data to
              other companies to market their products or services.
              <h3 className="detailsSubHeading">Who has the access to your data</h3>
              We don’t share your information, like email, name, etc, with third parties
              (subject to laws and regulations). Only our administrators have access to your
              data. No third party can access your data from us. However, you can review and
              delete your data whenever you want.
              <h3 className="detailsSubHeading">What rights do you have over your data</h3>
              Upon having an account on the{" "}
              <span style={{ fontWeight: 400 }}>Ziphost™</span> site or leaving comments,
              you can request an exported file of our personal data about you. You can also
              request that we erase your data, excluding the data we must retain for
              administrative, security, or legal purposes.
              <h3 className="detailsSubHeading">Third-party links on our site</h3>
              About any third-party links to products and services on our website, we have
              no responsibility or liability for the content and activities of these linked
              sites. Third-party sites have their privacy policies.
              <h3 className="detailsSubHeading">What policy do we have for children</h3>
              We recognize we have a special obligation to protect personal data obtained
              from children. We do not knowingly collect personal data from any child under
              16 without their parent or guardian's consent. If you are a parent or guardian
              concerned about transferring personal data about your child, please contact
              us.
              <h3 className="detailsSubHeading">What our cookies do on your browser</h3>
              <span style={{ fontWeight: 400 }}>Ziphost™</span> site uses cookies to
              identify and track visitors, their actions on site, and their website access
              preferences. <span style={{ fontWeight: 400 }}>Ziphost™</span> site visitors
              wishing not to have cookies placed on their browsers should turn off cookies
              while using Ziphost.app. In that case, you may lose some features of the
              website.
              <h3 className="detailsSubHeading">
                What data breach procedures do we have in place
              </h3>
              Suppose a data breach occurs, such as loss, alteration, unauthorized
              disclosure or access, personal data transmission, storage, or otherwise
              processing. In that case, we will notify you immediately after we become aware
              of such a breach.
              <h3 className="detailsSubHeading">
                How you can review, update, get a copy or delete your data
              </h3>
              You are free to review and update your information at any time directly
              through our site. Please log in to our site and click the "My Account" link to
              change your information. If you want to delete or get a copy of your data on
              our site, email support@getbizviz.com. We will do that immediately.
            </>
          </div>
        </div>
      </section>

      {isLoggedin === false || (isLoggedin === undefined && <Footer />)}
    </div>
  );
}

export default PrivacyPolicy;
